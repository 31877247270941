@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #000000 1.84%, #ff0000 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #000000 -13.86%, #ee0000 99.55%);
  
  --color-bg: #000000;
  --color-footer : #b94244;
  --color-blog: #750000;
  --color-text: #ffffff;
  --color-subtext: #ff0000;
}
