* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  /* ff 3.6+ */
  background:-moz-radial-gradient(circle at 3% 25%, rgb(63, 29, 29) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 3% 25%, rgb(63, 29, 29) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 3% 25%, rgb(63, 29, 29) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 3% 25%, rgb(63, 29, 29) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgb(63, 29, 29) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 100%);
}

.gradient__text {
  background: linear-gradient(to right, #ce5858 0%, #ff0000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}





body {
  font-family: var(--font-family);
  font-size: 16px;
  color: white;
}

.title {
  font-size: 15em;
  text-align: center;
  font-family: var(--font-family);
  color: white;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgb(220, 115, 61),
  0 0.4em 1.25em 0 rgb(213, 116, 9) !important;
  padding: 2em 3em !important;
}

.date {
  color: white;
}

#description {
  margin: 1.5em 0 2em 0;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}