.marquee-heading {
    text-align: center;
    margin-bottom: 50px;
    font-size: 40px;
    color: #ffffff;
}

@keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
}

.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 110%;
    display: flex;
}

.marquee-content {
    display: flex;
    animation: marquee 20s linear infinite;
    width: 400%;
}

.marquee-image {
    width: 230px;
    height: 230px;
    object-fit: cover;
    display: inline-block;
    margin-right: 100px;
}

.red-line {
    border: 0;
    border-top: 1px solid #ff0000;
    margin: 20px 0;
    width: 100%;
}