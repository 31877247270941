.team-container {
    text-align: center;
    padding: 20px;
}

.team-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
}

.team-member {
    position: relative;
    width: 200px;
    cursor: pointer;
    transition: transform 0.2s;
    background: linear-gradient(145deg, #802929, #000000);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 45px;
}

.team-member::before {
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border-radius: 20px;
    background: linear-gradient(145deg, #000000, #ff0000);
    z-index: -1;
}

.team-member:hover {
    transform: scale(1.05);
}

.team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 0 auto 10px;
}

.team-member h3, .team-member p {
    margin: 10px 0;
}

.member-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: linear-gradient(145deg, #3f1d1d, #cb5454);
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    max-width: 500px;
    width: 90%;
}

.popup-content img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.social-media a {
    margin: 0 10px;
    color: black;
    font-size: 24px;
    margin-top: 50px;
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background: #de8282;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background: #944a4a;
}

.team-heading {
    text-align: center;
    font-size: 40px;
    color: #ffffff;
}
