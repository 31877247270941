:root {
    --font-family: 'Manrope', sans-serif;
}

.navbar {
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    color: white;
}

.navbar-left, .navbar-center, .navbar-right {
    display: flex;
    align-items: center;
}

.navbar-left img {
    width: 45px;
    height: 45px;
    margin-right: 10px;
}

.nav-links {
    display: flex;
}

.navbar a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 10px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.navbar-center {
    flex-grow: 1;
    justify-content: center;
    font-size: 1.5em;
    color: red;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.navbar-right a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 10px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.navbar a:hover {
    text-decoration: underline;
}

.navbar-element {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-left: 10px;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
}

@media screen and (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 70px;
        left: 0;
        background-color: black;
        padding: 20px;
        margin: 10px;
        border-radius: 10px;
    }

    .nav-links.open {
        display: flex;
    }

    .navbar-center {
        display: none;
    }

    .navbar-right {
        display: none;
    }

    .hamburger {
        display: flex;
    }

    .bar.open:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .bar.open:nth-child(2) {
        opacity: 0;
    }

    .bar.open:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}
