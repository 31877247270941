.carousel-container {
    width: 60%;
    margin: 30px auto;
    position: relative;
}

.carousel-slide {
    position: relative;
    transition: transform 0.5s ease, opacity 0.5s ease;
    height: auto;
}

.carousel-image {
    width: 100%;
    height: 500px; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure no part of the image is cut off */
    display: block;
    margin: 0 auto;
}

.slick-center .carousel-slide {
    transform: scale(1.08); /* Enlarge the centered slide */
    z-index: 1; /* Bring the centered slide above the others */
    opacity: 1; /* Full opacity for the centered slide */
}

@media screen and (max-width: 768px) {
    .carousel-container {
        width: 90%;
    }

    .slick-prev, .slick-next {
        display: block !important;
        background: black;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        z-index: 2;
    }

    .slick-prev {
        left: 10px;
    }

    .slick-next {
        right: 10px;
    }
}
